import { Button } from '@/components/button';
import { Heading } from '@/components/heading';
import { ChevronLeftIcon } from 'lucide-react';
import React, { createContext, useEffect, useState } from 'react';
import { Organization, getOrganization } from '../../api/club/entities/organization';
import client from '../../api/people/client';
import { LayoutBase } from '../../components/layout/layout';
import { OrgPerson } from '../../core/orgPerson/types';
import { useProfiles } from '../profiles/use-profiles';
import { useSeason } from '../season';

export const loadOrgPerson = async (currentSeason: string) => {
	try {
		const { data } = await client.get<OrgPerson>(`org-people/me`, {
			headers: {
				'x-gle-current-season': currentSeason,
				'x-gle-selected-season': currentSeason, //let it be
			},
		});
		return data;
	} catch (err) {
		throw err;
	}
};

type OrgPersonContextType = {
	orgPerson: OrgPerson;
	organization?: Organization;
	refresh: () => void;
};

export const OrgPersonContext = createContext<OrgPersonContextType | undefined>(undefined);

export const OrgPersonProvider = (props: { children: React.ReactNode; fallback: React.ReactNode }) => {
	const { currentSeason } = useSeason();
	const { selectedProfile, unselectProfile } = useProfiles();

	const [organization, setOrganization] = useState<Organization | undefined>();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [orgPerson, setOrgPerson] = useState<OrgPerson>();

	useEffect(() => {
		void init({ loadOrganization: true });
	}, []);

	//SMELL
	const init = async (config?: { loadOrganization?: boolean }) => {
		if (!selectedProfile) return;

		setIsLoading(true);

		try {
			if (config?.loadOrganization) {
				const data = await getOrganization({ organizationId: selectedProfile.ref_organization });
				setOrganization(data);
			}

			const data = await loadOrgPerson(currentSeason);
			setOrgPerson(data);
		} catch (err) {
		} finally {
			setIsLoading(false);
		}
	};

	const refresh = async () => {
		void init();
	};

	if (isLoading) {
		return <>{props.fallback}</>;
	}

	if (!orgPerson) {
		return (
			<LayoutBase>
				<Heading level={2}>Si è verificato un errore</Heading>
				<p className="text-slate-600">
					Il profilo associato a questo tesserato è stato eliminato. Contatta la tua società per maggiori
					informazioni
				</p>

				<div className="mt-5 flex justify-center">
					<Button variant={'secondary'} onClick={unselectProfile}>
						<ChevronLeftIcon size={18} className="mr-2" />
						{'Indietro'}
					</Button>
				</div>
			</LayoutBase>
		);
	}

	return (
		<OrgPersonContext.Provider
			value={{
				orgPerson,
				refresh,
				organization,
			}}>
			{props.children}
		</OrgPersonContext.Provider>
	);
};
